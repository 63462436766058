import Head from "next/head";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const DynamicLanding = dynamic(() => import("@/components/Landing"));
const DynamicInfo = dynamic(() => import("@/components/Info"));
const DynamicContact = dynamic(() => import("@/components/Contact"));

const Home = () => {
  const metaTag = {
    image:
      "https://firebasestorage.googleapis.com/v0/b/portfolio-83773.appspot.com/o/Media%2FProjects%2FAlyssaGoldman%2Fbanner.png?alt=media&token=2eab7bd4-c2b3-4e21-b538-68a42eec098d",
    url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}`,
    description:
      "I'm a certified orientation and mobility specialist from New Jersey, working throughout the tri-state area with visually impaired and disabled individuals.",
    title: "Alyssa Goldman — NYC / NJ teacher for the blind.",
  };

  const router = useRouter();

  const canonicalUrl = (
    `${process.env.NEXT_PUBLIC_DOMAIN_URL}` +
    (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:image" content={metaTag.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:url" content={metaTag.url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={metaTag.image} />
        <meta name="twitter:description" content={metaTag.description} />
        <meta property="og:description" content={metaTag.description} />
        <meta name="description" content={metaTag.description} />
        <title>{metaTag.title}</title>
        <meta property="og:title" content={metaTag.title} key="title" />
        <meta name="twitter:title" content={metaTag.title} />
      </Head>
      <DynamicLanding />
      <DynamicInfo />
      <DynamicContact />
    </>
  );
};

export default Home;
